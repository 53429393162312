<template>
  <b-button
    v-on="$listeners"
    :pill="isPill"
    @click="
      {
        $emit('input', !value);
      }
    "
    :variant="value ? 'danger' : 'primary'"
  >
    <i class="mdi mdi-16px" :class="{ [value ? onIcon : offIcon]: true }"></i>

    {{ value ? onLabel : offLabel }}
  </b-button>
</template>

<script>
export default {
  name: "ButtonSwitch",

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    isPill: {
      type: Boolean,
      default: true,
    },

    onLabel: {
      type: String,
      default: "Ligado",
    },

    offLabel: {
      type: String,
      default: "Desligado",
    },

    onIcon: {
      type: String,
      default: "mdi-plus",
    },

    offIcon: {
      type: String,
      default: "mdi-close",
    },
  },
};
</script>
