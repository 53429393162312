<template>
  <div class="m-3">
    <div class="container">
      <div class="row mb-4">
        <template v-if="question.isReport">
          <div class="col-md-3 col-12">
            <h5>Número: {{ question.number }}</h5>
          </div>
          <div class="col-md-3 col-12">
            <h5>Origem: {{ question.source }}</h5>
          </div>
          <div class="col-md-6 col-12">
            <h5>Título: {{ question.title }}</h5>
          </div>
        </template>
        <template v-else>
          <div class="col-md-3 col-12">
            <h5>Ano: {{ question.year }}</h5>
          </div>
          <div class="col-md-3 col-12">
            <h5>Banca: {{ question.jury }}</h5>
          </div>
          <div class="col-md-3 col-12">
            <h5>Órgão: {{ question.institute }}</h5>
          </div>
          <div class="col-md-3 col-12">
            <h5>Cargo: {{ question.role }}</h5>
          </div>
        </template>
      </div>
      <h4>
        <div v-html="question.question"></div>
      </h4>
    </div>

    <div class="container my-4">
      <template v-if="!question.isReport">
        <h3 class="mb-4">
          Resposta correta:
          <span class="text-info text-uppercase">
            {{ question.realAnswer }}
          </span>
        </h3>

        <h4 class="mb-3">
          Alternativas
        </h4>

        <div class="card bg-secondary text-light">
          <div class="p-2">
            <h4>A</h4>
            <p>
              {{ question.answerA }}
            </p>
          </div>
        </div>
        <div class="card bg-secondary text-light">
          <div class="p-2">
            <h4>B</h4>
            <p>
              {{ question.answerB }}
            </p>
          </div>
        </div>
        <div class="card bg-secondary text-light">
          <div class="p-2">
            <h4>C</h4>
            <p>
              {{ question.answerC }}
            </p>
          </div>
        </div>
        <div class="card bg-secondary text-light">
          <div class="p-2">
            <h4>D</h4>
            <p>
              {{ question.answerD }}
            </p>
          </div>
        </div>
        <div class="card bg-secondary text-light">
          <div class="p-2">
            <h4>E</h4>
            <p>
              {{ question.answerE }}
            </p>
          </div>
        </div>

        <div class="my-3">
          <h5 class="mb-2">Interesses</h5>
          <div v-if="question.interests.length">
            <span
              v-for="interest in question.interests"
              :key="interest.id"
              class="badge badge-pill badge-dark ml-1 p-2"
              style="font-size: 100%;"
            >
              {{ interest.name }}
            </span>
          </div>
          <div v-else>
            <span class="text-danger">Nenhum interesse encontrado!!</span>
          </div>
        </div>
      </template>

      <div class="my-3">
        <h5 class="mb-2">Análises</h5>

        <b-card
          bg-variant="light"
          title="Caderno de revisões"
          tag="article"
          class=""
        >
          <QuillEditor
            class="mb-2"
            :options="{
              placeholder: 'Escreva a revisão consolidada',
              modules: {
                toolbar: [['bold', 'italic', 'underline']],
              },
            }"
            v-model="finalReview"
          ></QuillEditor>

          <b-button
            @click="saveFinalReview"
            :disabled="savingFinalReview"
            variant="success"
            >Salvar revisão</b-button
          >
        </b-card>

        <hr />

        <table class="table stripe-each-three">
          <thead>
            <tr>
              <th>Ações</th>
              <th>Usuário</th>
              <th>Original/Edição</th>
              <th>Áudio</th>
              <th>Vídeo</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(review, i) in reviews">
              <tr :key="`${review.id}-1`">
                <th scope="row">
                  <b-button-group size="sm">
                    <b-button
                      variant="secondary"
                      :disabled="i === reviews.length - 1"
                      @click="moveDown(review)"
                    >
                      <i class="mdi mdi-arrow-down-bold"></i>
                    </b-button>
                    <b-button
                      :disabled="i === 0"
                      variant="primary"
                      @click="moveUp(review)"
                    >
                      <i class="mdi mdi-arrow-up-bold"></i>
                    </b-button>
                  </b-button-group>
                </th>
                <td>
                  <router-link
                    :to="{
                      name: 'profile',
                      params: { username: review.user.username },
                    }"
                    target="_blank"
                  >
                    @{{ review.user.username }}
                  </router-link>
                </td>
                <td>
                  <div><strong>Conteúdo original</strong></div>
                  <p v-if="review.content" v-html="review.content"></p>
                  <p v-else>-</p>
                </td>
                <td>
                  <div><strong>Áudio original</strong></div>

                  <div v-if="review.audio">
                    <audio controls class="review-audio">
                      <source :src="review.audio.name | blob" />
                      Seu navegador não suporta áudio.
                    </audio>
                  </div>
                  <div v-else>-</div>
                </td>
                <td>
                  <strong>Vídeo original</strong>

                  <div v-if="review.youtubeId">
                    <iframe
                      width="240"
                      height="120"
                      :src="`https://www.youtube.com/embed/${review.youtubeId}`"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      class="mt-2"
                    ></iframe>
                  </div>
                  <div v-else>-</div>
                </td>
              </tr>

              <tr :key="`${review.id}-2`">
                <td>
                  <b-badge
                    v-if="(
                      review.editedContent !== '' ||
                          review.editedAudioBlobId !== null ||
                          review.editedYoutubeId !== ''
                    )"
                    variant="warning"
                    style="font-size: 14px;"
                  >
                    Precisa de<br />avaliação
                  </b-badge>
                  <b-badge
                    v-else-if="review.requestedChanges"
                    variant="danger"
                    style="font-size: 14px;"
                  >
                    Mudanças<br />requisitadas
                  </b-badge>
                  <b-badge
                    v-else-if="!review.requestedChanges && review.active"
                    variant="success"
                    style="font-size: 14px;"
                  >
                    Ativo
                  </b-badge>
                </td>

                <td></td>

                <td>
                  <div><strong>Conteúdo editado</strong></div>

                  <p v-if="!review.editedContent">
                    {{ "-" }}
                  </p>

                  <QuillEditor
                    v-else
                    :options="{
                      placeholder: 'Insira o texto aqui...',
                      modules: {
                        toolbar: [['bold', 'italic', 'underline']],
                      },
                    }"
                    v-model="review.editedContent"
                  ></QuillEditor>
                </td>

                <td>
                  <strong>Áudio modificado</strong>
                  <div v-if="review.editedAudio">
                    <audio controls class="review-audio">
                      <source :src="review.editedAudio.name | blob" />
                      Seu navegador não suporta áudio.
                    </audio>
                  </div>
                  <div v-else>-</div>
                </td>
                <td>
                  <strong>Vídeo editado</strong>

                  <div v-if="review.editedYoutubeId">
                    <iframe
                      width="240"
                      height="120"
                      :src="`https://www.youtube.com/embed/${review.editedYoutubeId}`"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      class="mt-2"
                    ></iframe>
                  </div>
                  <div v-else>-</div>
                </td>
              </tr>

              <tr :key="`${review.id}-3`">
                <td colspan="2">
                  <b-button-group>
                    <b-button
                      variant="danger"
                      @click.prevent="deleteReview(review)"
                    >
                      <i class="mdi mdi-trash-can"></i>
                    </b-button>
                    <b-button
                      variant="warning"
                      :disabled="
                        review.requestedChanges ===
                          review.preparingToRequestChanges ||
                        review.preparingToRequestChanges.trim().length === 0
                      "
                      @click.prevent="requestChanges(review)"
                    >
                      <i class="mdi mdi-close"></i>
                    </b-button>
                    <b-button
                      :disabled="
                        review.preparingToRequestChanges.trim().length > 0 ||
                        (review.editedContent === '' &&
                          review.editedAudioBlobId === null &&
                          review.editedYoutubeId === '')
                      "
                      variant="success"
                      @click.prevent="approveReview(review)"
                    >
                      <i class="mdi mdi-check-bold"></i>
                    </b-button>
                  </b-button-group>
                </td>

                <td colspan="3">
                  <b-form-textarea
                    id="textarea"
                    v-model="review.preparingToRequestChanges"
                    placeholder="Peça alterações ou deixe vazio para poder aprovar"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div class="my-3">
        <h5>Erros reportados</h5>
        <template v-if="question.reports && question.reports.length">
          <p v-html="question.reports.split('\n').join('<br>')"></p>
        </template>

        <template v-else><strong>Sem erros reportados</strong></template>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "ViewQuestionModal",

  components: { QuillEditor: quillEditor },

  props: {
    question: Object,
  },

  data() {
    return {
      savingFinalReview: false,
      finalReview: "",
      reviews: [],
    };
  },

  created() {
    this.finalReview = this.question.finalReview;
    this.loadReviews();
  },

  methods: {
    saveFinalReview() {
      this.savingFinalReview = true;

      api
        .updateQuestion(this.question.id, { finalReview: this.finalReview })
        .then((question) => {
          this.question.finalReview = question.finalReview;
        })
        .catch(this.genericErrorHandler)
        .finally(() => {
          this.savingFinalReview = false;
        });
    },

    loadReviews() {
      api
        .getQuestionReviews(this.question.id, { adminMode: true })
        .then((reviews) => {
          this.reviews = reviews.map((review) => ({
            ...review,
            preparingToRequestChanges: review.requestedChanges,
          }));
        })
        .catch(() => {
          this.$swal(
            "Opa",
            "Algo deu errado carregando as revisões",
            "warning"
          );
        });
    },

    confirmAction(title) {
      return this.$swal({
        title: `Você tem certeza que deseja ${title}?`,
        text: "Esta ação não pode ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#02a499",
        cancelButtonColor: "#ec4561",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar!",
      }).then((result) => {
        return result.value;
      });
    },

    async approveReview(review) {
      if (!(await this.confirmAction("aprovar esta revisão"))) {
        return;
      }

      const updated = await api
        .updateReview(
          this.question.id,
          review.id,
          {
            active: true,
            content: review.editedContent ? review.editedContent : undefined,
            audioBlobId: review.editedAudioBlobId
              ? review.editedAudioBlobId
              : undefined,
            youtubeId: review.editedYoutubeId
              ? review.editedYoutubeId
              : undefined,
            editedContent: "",
            editedAudioBlobId: null,
            editedYoutubeId: "",
            requestedChanges: "",
          },
          { adminMode: true, notificatonType: "approved" }
        )
        .catch(this.genericErrorHandler);

      Object.assign(review, updated);
    },

    async requestChanges(review) {
      if (!(await this.confirmAction("pedir melhoras para esta revisão"))) {
        return;
      }

      const updated = await api
        .updateReview(
          this.question.id,
          review.id,
          {
            requestedChanges: review.preparingToRequestChanges,
          },
          { adminMode: true, notificatonType: "changesRequested" }
        )
        .catch(this.genericErrorHandler);

      Object.assign(review, updated);
    },

    async deleteReview(review) {
      if (!(await this.confirmAction("apagar esta revisão"))) {
        return;
      }

      await api
        .deleteReview(this.question.id, review.id, {
          adminMode: true, notificationType: "deleted",
        })
        .catch(this.genericErrorHandler);

      this.reviews = this.reviews.filter((r) => r.id !== review.id);
    },

    async moveUp(review) {
      const index = this.reviews.indexOf(review);
      if (index === 0) {
        return;
      }
      const other = this.reviews[index - 1];
      await this.switchReviews(review, other);
    },

    async moveDown(review) {
      const index = this.reviews.indexOf(review);
      if (index === this.reviews.length - 1) {
        return;
      }
      const other = this.reviews[index + 1];
      await this.switchReviews(review, other);
    },

    async switchReviews(reviewA, reviewB) {
      try {
        const updated = await api.switchReviewOrders(
          this.question.id,
          reviewA.id,
          reviewB.id
        );

        this.$set(this.reviews, this.reviews.indexOf(reviewA), {
          ...reviewA,
          order: updated.reviewA.order,
        });
        this.$set(this.reviews, this.reviews.indexOf(reviewB), {
          ...reviewB,
          order: updated.reviewB.order,
        });

        this.reviews.sort((a, b) => Number(a.order) - Number(b.order));
      } catch (err) {
        this.genericErrorHandler(err);
      }
    },

    genericErrorHandler(err) {
      this.$swal("Opa", "Algo deu errado atualizando uma revisão", "error");
      throw err;
    },
  },
};
</script>

<style>
.stripe-each-three tr:nth-child(6n-2),
.stripe-each-three tr:nth-child(6n-1),
.stripe-each-three tr:nth-child(6n) {
  background-color: rgba(0, 0, 0, 0.1);
}
.ql-editor strong {
  font-weight: 700;
}
</style>
