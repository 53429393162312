<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h3 class="">Gerenciamento de conteúdo</h3>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-right d-none d-md-block">
          <ButtonSwitch
            v-model="isReport"
            on-label="Informativos"
            off-label="Questões"
            on-icon="mdi-swap-vertical"
            off-icon="mdi-swap-vertical"
            :is-pill="false"
            class="mr-2"
            @input="
              (val) => {
                isReport = val;
                $refs.tableQuestions.refresh();
              }
            "
          />
          <router-link
            :to="{ name: 'createQuestion' }"
            class="btn btn-info mr-2"
          >
            <i class="mdi mdi-plus"></i>
            Cadastrar conteúdo
          </router-link>
          <button
            class="btn btn-success"
            @click.prevent="$refs.tableQuestions.refresh()"
          >
            <i class="mdi mdi-refresh"></i>
            Atualizar resultados
          </button>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-body">
        <div class="row mb-md-2">
          <div
            v-if="
              ['admin', 'reviewer', 'content-manager'].includes(
                currentUser.role
              )
            "
            class="col-sm-12 col-md-6"
          >
            <div class="dataTables_length">
              <label
                v-if="['admin', 'reviewer'].includes(currentUser.role)"
                class="d-inline-flex align-items-center mr-md-3"
              >
                <b-form-checkbox
                  v-model="reviewNeededOnly"
                  @change="$refs.tableQuestions.refresh()"
                >
                  Com revisões para análise
                </b-form-checkbox>
              </label>
              <label
                v-if="['admin', 'content-manager'].includes(currentUser.role)"
                class="d-inline-flex align-items-center"
              >
                <b-form-checkbox
                  v-model="errorsReportedOnly"
                  @change="$refs.tableQuestions.refresh()"
                >
                  Com problemas reportados
                </b-form-checkbox>
              </label>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter">
              <div class="text-md-right">
                <label class="d-inline-flex align-items-center">
                  Pesquisar trecho:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Pesquisar..."
                    class="form-control ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive mb-0">
          <b-table
            ref="tableQuestions"
            :items="questionsProvider"
            :fields="fields"
            responsive
            flex
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(partial)="data">
              {{
                data.item.question.length > 100
                  ? `${$options
                      .stripHtml(data.item.question)
                      .result.slice(0, 100)}...`
                  : $options.stripHtml(data.item.question).result
              }}
            </template>

            <template v-slot:cell(actions)="data">
              <button class="btn btn-info mr-2" @click="modalInfo(data.item)">
                Visualizar
              </button>
              <button
                v-if="['admin', 'content-manager'].includes(currentUser.role)"
                @click="editQuestion(data.item)"
                class="btn btn-warning mr-2"
              >
                Editar
              </button>
              <button
                v-if="['admin', 'content-manager'].includes(currentUser.role)"
                class="btn btn-danger"
                @click="deleteQuestion(data.item)"
              >
                Excluir
              </button>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-container fluid>
      <b-modal
        v-if="infoModal.content"
        :id="infoModal.id"
        :title="infoModal.title"
        size="xl"
        ok-only
        @hide="resetInfoModal"
      >
        <ViewQuestionModal :question="infoModal.content"></ViewQuestionModal>
      </b-modal>
    </b-container>
  </Layout>
</template>

<script>
import stripHtml from "string-strip-html";
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import ButtonSwitch from "@/components/ButtonSwitch";

import { mapState } from "vuex";

import * as api from "@/api";
import Swal from "sweetalert2";

import ViewQuestionModal from "@/components/ViewQuestionModal";

export default {
  stripHtml,

  name: "ManageQuestions",

  page: {
    title: "Gerenciamento de Perguntas",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    ViewQuestionModal,
    ButtonSwitch,
  },

  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "year",
      sortDesc: true,
      reviewNeededOnly: false,
      isReport: false,
      errorsReportedOnly: false,
      infoModal: {
        id: "info-modal",
        title: "",
        content: null,
      },
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    fields() {
      if (this.isReport) {
        return [
          { key: "number", label: "Número", sortable: true },
          { key: "source", label: "Origem", sortable: false },
          { key: "title", label: "Título", sortable: false },
          { key: "partial", label: "Trecho", sortable: false },
          { key: "actions", label: "Opções", sortable: false },
        ];
      }

      return [
        { key: "year", label: "Ano", sortable: true },
        { key: "jury", label: "Banca", sortable: true },
        { key: "institute", label: "Órgão", sortable: true },
        { key: "role", label: "Cargo", sortable: true },
        { key: "partial", label: "Trecho", sortable: false },
        { key: "actions", label: "Opções", sortable: false },
      ];
    },
  },

  methods: {
    questionsProvider(ctx) {
      const params = {
        page: ctx.currentPage,
        limit: ctx.perPage,
        search: ctx.filter ? ctx.filter : undefined,
        order: ctx.sortBy ? ctx.sortBy : undefined,
        direction: ctx.sortDesc ? "desc" : "asc",
        isReport: this.isReport,
        adminMode: true,
        reviewNeededOnly: this.reviewNeededOnly,
        errorsReportedOnly: this.errorsReportedOnly,
      };
      return api
        .getQuestions(params)
        .then((pagination) => {
          this.totalRows = pagination.total;
          return pagination.items;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar perguntas!", "error");
          return [];
        });
    },

    editQuestion(question) {
      return this.$router.push({
        name: "editQuestion",
        params: {
          id: question.id,
        },
      });
    },

    deleteQuestion(question) {
      this.$swal({
        title: "Você tem certeza?",
        text: "Esta ação não pode ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#02a499",
        cancelButtonColor: "#ec4561",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, deletar!",
      }).then((result) => {
        if (result.value) {
          return this.doDeleteQuestion(question);
        }
      });
    },

    doDeleteQuestion(question) {
      if (question.id) {
        Swal.fire({
          icon: "success",
          title: "Pergunta removida!",
        });
      }

      api
        .deleteQuestion(question.id)
        .then(() => {
          this.$refs.tableQuestions.refresh();
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Pergunta removida!",
          });
        })
        .catch((err) => {
          if (err) {
            this.$swal("Opa", "Falha ao remover pergunta", "error");
          } else {
            this.$swal.stopLoading();
            this.$swal.close();
          }
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    modalInfo(item) {
      this.infoModal.title = "Visualizar";
      this.infoModal.content = item;
      setTimeout(() => {
        this.$root.$emit("bv::show::modal", this.infoModal.id);
      }, 1);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = null;
    },
  },
};
</script>
